<template>
  <Layout>
    <PageHeader title="Create Invoice" />

    <div class="row p-4 flex justify-center">
      <div class="col-md-10 p-4 bg-light">
        <div class="form-group">
          <label for="Custommer-number">Customer Internal Number</label>
          <input
            type="text"
            placeholder="enter custommer internal Number"
            class="form-control"
            v-model="clientinternalnumber"
          />
        </div>

        <!-- Dynamic inputs -->
        <div class="form-group">
          <label for="Customer Type"> Select Customer Type</label>
          <select
            v-model="clienttypeid"
            class="form-control"
            name="customer type"
          >
            <option value="1">Oonpay customer</option>
            <option value="2">Other customer</option>
          </select>
        </div>
        <div v-if="clienttypeid == 2">
          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="First Name">Customer First Name</label>
                <input
                  type="name"
                  placeholder="enter first name"
                  class="form-control"
                  v-model="clientfirstname"
                />
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="Last Name">Customer Last Name</label>
                <input
                  type="name"
                  placeholder="enter last name"
                  class="form-control"
                  v-model="clientlastname"
                />
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col">
              <div class="form-group">
                <label for="customer number">Customer Phone Number</label>
                <input
                  style="background-color: #d3d3d3; text-align:left;"
                  type="button"
                  v-model="clientphonenum"
                  placeholder="enter phone number"
                  class="form-control"
                  data-toggle="modal"
                  data-target="#businesPhonModalCenter"
                />
              </div>

              <!-- Modal -->
              <div
                class="modal fade"
                id="businesPhonModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="businesPhonModalCenterTitle"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered" role="document">
                  <div class="modal-content pb-5">
                    <div class="modal-header">
                      <h5 class="modal-title" id="businesPhonModalLongTitle">
                        Verify Customer Phone
                      </h5>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body space-y-5">
                      <div v-if="show_confirm == 1">
                        <div class="row flex justify-content-center">
                          <div class="col-md-10">
                            <div class="form-group">
                              <label for="countries">Select country code</label>
                              <select
                                v-model="selected_code"
                                class="form-control"
                                name="countries"
                              >
                                <option
                                  v-for="country in countries_code"
                                  :key="country.index"
                                  :value="country"
                                >
                                  {{ country.callCode }}
                                </option>
                              </select>
                            </div>
                            <div class="form-group">
                              <input
                                type="number"
                                v-model="customerphonenumber"
                                placeholder="enter phone number"
                                class="form-control"
                              />
                            </div>

                            <button
                              @click.prevent="sendCode"
                              class="btn btn-block btn-primary"
                            >
                              send code
                            </button>
                          </div>
                        </div>
                      </div>
                      <div v-if="show_confirm == 2">
                        <div class="row flex justify-content-center">
                          <div class="col-md-10">
                            <div class="form-group">
                              <input
                                type="text"
                                placeholder="xxxxxx"
                                class="form-control"
                                v-model="confirm_otp"
                              />
                            </div>
                            <button
                              @click.prevent="verifyCode"
                              class="btn btn-block btn-primary"
                            >
                              verify
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label for="customer email">Customer Email</label>
                <input
                  type="name"
                  placeholder="enter email address"
                  class="form-control"
                  v-model="clientemail"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="clienttypeid == 1" class="form-group">
          <div class="form-group">
            <label for="">Select Country code</label>
            <div class="form-row">
              <div class="col-sm-3">
                <select class="form-control" v-model="clientcountrycode">
                  <option
                    v-for="(code, key) in countries_code"
                    :value="code"
                    :key="key"
                    >{{ code.callCode }}</option
                  >
                </select>
              </div>
              <div class="col-md-9">
                <input
                  v-model="clientephone"
                  type="text"
                  placeholder="Enter phone number"
                  class="form-control"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="loading" class="d-flex justify-content-center mb-3">
          <b-spinner
            style="width: 3rem; height: 3rem;"
            label="Large Spinner"
          ></b-spinner>
        </div>

        <!-- Dynamic input -->
        <div class="form-group py-4 space-x-4">
          <label for="Service">Medication(s) Offered</label>
          <button
            data-toggle="modal"
            data-target="#exampleModalCenter"
            type="button"
            class="btn btn-success btn-sm rounded-xl"
          >
            <i class="fa fa-plus-square mr-1" aria-hidden="true"></i>Add
            medication
          </button>

          <div v-if="medications.length > 0">
            <div
              v-for="(medi, key) in medications"
              :key="key"
              class="result bg-oonpayprimary text-white px-4 rounded-2xl py-1 mt-3"
            >
              <div class="d-flex justify-content-between">
                <span>{{ medi.selectedMedicine }}</span>
                <span class="space-x-4"
                  ><span>{{ medi.quantity }} Units</span>
                  <span> GHC{{ medi.unitPrice }}</span></span
                >
              </div>
              <div class="d-flex justify-content-between">
                <span
                  >Total Price: GHC{{ medi.unitPrice * medi.quantity }}</span
                >
                <span @click="DeleteMed(key)" class="text-oonpaysecondary"
                  ><i class="fa fa-trash" aria-hidden="true"></i
                ></span>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal -->
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Add Medication
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <!-- Add Medication -->
                <div class="form-group">
                  <label for="Medication">
                    Medication
                  </label>
                  <input
                    class="form-control"
                    name=""
                    type="text"
                    v-model="searchQuery"
                  />
                  <div v-if="searchQuery">
                    <!-- <div v-for="r of resultQuery" :key="r.id">{{r.fullName}}</div> -->

                    <ul v-if="!isHidden" class="list-group">
                      <li
                        class="list-group-item"
                        v-for="(suggestion, index) in resultQuery"
                        :key="index"
                        @click="suggestionClick(suggestion.id)"
                      >
                        <a class="text-oonpaysecondary" href="#"
                          >{{ suggestion.fullName }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="form-group">
                  <label for="Qunatity">Quantity</label>
                  <input
                    type="number"
                    v-model="quantity"
                    placeholder="0"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <label for="unit price">Unit Price</label>
                  <input
                    type="number"
                    v-model="unitPrice"
                    placeholder="0"
                    class="form-control"
                  />
                </div>

                <button
                  @click="addMedication"
                  type="button"
                  class="btn btn-info btn-block"
                >
                  <i class="fa fa-medkit" aria-hidden="true"></i> Add Medication
                </button>

                <div v-if="medications.length > 0">
                  <div
                    v-for="(medi, key) in medications"
                    :key="key"
                    class="result bg-oonpayprimary text-white px-4 rounded-2xl py-1 mt-3"
                  >
                    <div class="d-flex justify-content-between">
                      <span>{{ medi.selectedMedicine }}</span>
                      <span class="space-x-4"
                        ><span>{{ medi.quantity }} Units</span>
                        <span> GHC{{ medi.unitPrice }}</span></span
                      >
                    </div>
                    <div class="d-flex justify-content-between">
                      <span
                        >Total Price: GHC{{
                          medi.unitPrice * medi.quantity
                        }}</span
                      >
                      <span @click="DeleteMed(key)" class="text-oonpaysecondary"
                        ><i class="fa fa-trash" aria-hidden="true"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="medications.length > 0" class="modal-footer">
                <p class="font-bold" style=" margin-right:120px;">
                  <span class="font-bold text-2xl"
                    >Total Cost: {{ calCulateTotal }}</span
                  >
                </p>
                <button type="button" class="btn btn-info" data-dismiss="modal">
                  Done
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="Total Cost">Total Cost </label>
              <input
                style="background-color: #d3d3d3;"
                disabled
                type="number"
                class="form-control text-muted"
                placeholder="total cost"
                :value="calCulateTotal"
              />
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="payment due">Select Payment Due Date</label>
              <input
                type="date"
                name="data"
                class="form-control"
                placeholder="enter payment due date"
                v-model="duedate"
              />
            </div>
          </div>
        </div>

        <div class="form-group mt-4">
          <div class="custom-file">
            <input
              type="file"
              @change="uploadFile"
              class="custom-file-input"
              multiple
              accept="application/pdf,application/msword,
              application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ref="files"
            />
            <label class="custom-file-label" for="upload docs"
              >upload documents here</label
            >
            <div
              v-for="(file, key) in Documents"
              :key="key"
              class="result bg-oonpayprimary text-white px-4 mx-40 rounded-2xl py-1 mt-3"
            >
              <div class="d-flex justify-content-between">
                <span> {{ file.name }}</span>
                <span
                  @click="deleteUpdatedDoc(key)"
                  class="text-oonpaysecondary"
                  ><i class="fa fa-trash" aria-hidden="true"></i
                ></span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group pb-4">
          <label for="Customer Phone Number"></label>
          <div class="form-row">
            <div class="col-sm-3">
              <label for="select country code">Select country code</label>
              <select
                v-model="selected_callCode"
                class="form-control"
                name="countries"
              >
                <option
                  v-for="country in countries_code"
                  :key="country.index"
                  :value="country.callCode"
                >
                  {{ country.callCode }}
                </option>
              </select>
            </div>
            <div class="col-sm-6">
              <label for="select country code">Enter phone number</label>
              <input
                type="number"
                v-model="benefactor"
                class="form-control"
                placeholder="enter benefactory phone"
              />
            </div>
            <div class="col-md-3 mtbtn">
              <button @click.prevent="getBenefactor" class="btn btn-info">
                Add
              </button>
            </div>
          </div>
          <div
            v-if="phonenumbers.length > 0"
            class="row flex mt-3 justify-center"
          >
            <div class="col-md-6">
              <ul
                v-for="(num, key) in phonenumbers"
                :key="key"
                class="list-group"
              >
                <li class="list-group-item d-flex justify-between">
                  <span>
                    {{
                      num.firstName +
                        " " +
                        num.lastName +
                        " " +
                        "(" +
                        num.phoneNumber +
                        ")"
                    }}</span
                  >
                  <span @click="deleteNumber(key)"
                    ><span
                      class="iconify"
                      data-icon="fluent:delete-dismiss-28-filled"
                      data-inline="false"
                    ></span
                  ></span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <button
          @click.prevent="handleSubmitInvoice"
          class="btn my-4 btn-info btn-sm btn-block"
        >
          Create Invoice
        </button>
      </div>
    </div>
    <modal @close="toggleModal" :modalActive="modalActive">
      <div class="modal-content">
        <p class="text-center text-danger">{{ errorMessage }}</p>
      </div>
    </modal>
  </Layout>
</template>

<script>
import Layout from "../../../views/layouts/main.vue";
import PageHeader from "@/components/page-header";
import $ from "jquery";
import Modal from "../../Global/Modal.vue";
export default {
  components: { Layout, PageHeader, Modal },
  data() {
    return {
      loading: false,
      clientinternalnumber: "",
      clientfirstname: "",
      clientlastname: "",
      clientemail: "",
      clienttypeid: "",
      clientcountryId: "",
      clientephone: "",

      totalcost: "",
      duedate: "",
      benefactors: [],
      Documents: [],
      ServicesOffered: [],
      selected_callCode: null,
      confirm_otp: null,
      show_confirm: 1,

      options: [],
      isHidden: false,

      medications: [],
      value: [],
      selectedMedicine: null,
      minMatchingChars: 1,
      quantity: null,
      unitPrice: null,
      newvalue: "",
      clientcountrycode: "",
      clientphonenum: "",

      searchQuery: null,
      isActive: true,

      mediId: null,
      phonenumbers: [],
      countries_code: [],
      phone: "",
      files: null,
      benefactor: "",
      benefactorsGuid: [],

      selected_code: null,
      customerphonenumber: null,

      customer_type: "",
      merchantguid: window.localStorage.getItem("merchantGUID"),
      employeeGUID: window.localStorage.getItem("employeeGUID"),
      modalActive: false,
      errorMessage: ""
    };
  },

  methods: {
    suggestionClick(index) {
      this.options.forEach((data) => {
        if (data.id == index) {
          this.searchQuery = data.fullName;
          this.mediId = data.id;
          this.isHidden = true;
        }
      });
    },

    toggleModal() {
      this.modalActive = false;
    },

    //Remove List
    DeleteMed(key) {
      this.medications.splice(key, 1);
    },
    // Get Invoice Required data
    GetInvoiceRequiredData() {
      const data = {
        id: this.merchantguid
      };
      this.$store
        .dispatch("pharmacy/GetInvoiceRequiredData", data)
        .then(({ data }) => {
          this.options = data.item2;
          this.countries_code = data.item1;
        });
    },

    //add medication
    addMedication() {
      const data = {
        selectedMedicine: this.searchQuery,
        quantity: this.quantity,
        unitPrice: this.unitPrice,
        id: this.mediId || 0,
        total: this.quantity * this.unitPrice
      };
      this.medications.push(data);
      (this.searchQuery = ""), (this.unitPrice = ""), (this.quantity = "");
      this.mediId = "";
      this.isHidden = false;
    },

    //Send OTP
    sendCode() {
      let newPhone = this.customerphonenumber;
      if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
        var phone = newPhone.substring(1);
      }
      let main_number = `${this.selected_code.callCode}${phone}`;

      const data = {
        code: this.selected_code.callCode,
        phonenumber: main_number
      };
      this.$store
        .dispatch("pharmacy/VerifyCustomerPhone", data)
        .then((response) => {
          if (response.status == 200) {
            this.show_confirm = 2;
            this.otp = response.data;
            // console.log(this.otp)
            this.customerphonenumber = "";
          }
        });
      this.$store.dispatch("pharmacy/CustomerPhoneNum", main_number);
    },

    verifyCode() {
      this.$store
        .dispatch("healthhub/verifyOTP", this.confirm_otp)
        .then((response) => {
          if (response.status == 200) {
            localStorage.setItem("newcusphone", response.data);
            $("#businesPhonModalCenter").modal("hide");
            this.show_confirm = 1;
            this.confirm_otp = "";
            this.clientphonenum = this.$store.state.pharmacy.customerphonenumber;
            this.$toasted.success("phone number confirmed successfully", {
              duration: 5000
            });
          }
        })
        .catch(() => {
          this.$toasted.error("invalid number please enter valid number", {
            duration: 5000
          });
        });
    },

    // Benefactor
    getBenefactor() {
      this.loading = true;
      let newPhone = this.benefactor;
      if (newPhone.charAt(0) == 0 || newPhone.charAt(0) == 1) {
        var benefactornumber = newPhone.substring(1);
      }

      let main_number = `${this.selected_callCode}${benefactornumber ||
        newPhone}`;
      const data = {
        phonenumber: main_number
      };
      this.$store
        .dispatch("invoice/GetBenefactor", data)
        .then((response) => {
          if (response.status == 200) {
            this.phonenumbers.push(response.data);
            this.benefactorsGuid.push({ clientGUID: response.data.guid });
            this.benefactor = "";
            this.loading = false;
          } else {
            this.modalActive = true;
            this.errorMessage = "Sorry Your number is not on Oonpay";
          }
        })
        .catch(() => {
          this.loading = false;
          this.modalActive = true;
          this.errorMessage = "Your number is not on Oonpay";
        });
    },

    // delete number
    deleteNumber(key) {
      const index = this.phonenumbers.indexOf(key);
      this.phonenumbers.splice(index, 1);
    },

    deleteUpdatedDoc(key) {
      this.Documents.splice(key, 1);
    },

    uploadFile(e) {
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.Documents.push(this.$refs.files.files[i]);
      }
    },

    handleSubmitInvoice() {
      this.loading = true;
      //client phone number
      let phone = this.clientephone;
      if (phone.charAt(0) == 0 || phone.charAt(0) == 1) {
        var phonenum = phone.substring(1);
      }

      let clientephone = `${this.clientcountrycode.callCode}${phonenum}`;
      let countryId = this.clientcountrycode.id || this.selected_code.id;

      //get services offered guid array
      const services = this.medications.map((item) => {
        const container = {
          ServiceFullName: item.selectedMedicine,
          ServiceQuantity: item.quantity,
          ServiceUnitPrice: item.unitPrice,
          ServiceId: item.id
        };

        return container;
      });

      //get benefactor numbers
      let benefactor = this.benefactorsGuid.map((item) => {
        const num = {
          clientGUID: item.clientGUID
        };

        return num;
      });

      const formData = new FormData();
      formData.append("clienttypeid", this.clienttypeid);
      formData.append("clientinternalnumber", this.clientinternalnumber);
      formData.append("ClientPhoneNumber", this.clientphonenum || clientephone),
        formData.append("clientfirstname", this.clientfirstname);
      formData.append("clientlastname", this.clientlastname);
      formData.append("clientemail", this.clientemail);
      formData.append("clientcountryId", countryId);
      formData.append("EmployeeGUID", this.employeeGUID);
      formData.append("TotalCost", this.$store.state.pharmacy.sum);
      formData.append("DueDate", this.duedate);
      formData.append("ServiceStartDate", "2020-09-10");
      formData.append("ServiceEndDate", "2020-09-10");
      formData.append("benefactors", JSON.stringify(benefactor));
      formData.append("ServicesOffered", JSON.stringify(services));

      var self = this;
      for (let i = 0; i < this.Documents.length; i++) {
        let file = self.Documents[i];
        formData.append("New File", file);
      }

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      this.$store
        .dispatch("pharmacy/CreateInvoice", formData)
        .then((response) => {
          if (response.status == 200) {
            this.loading = false;
            this.$router.push("/pharmacy-invoices");
            this.$toasted.success("Invoice created successfully", {
              duration: 5000
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          err.response.data.map((res) => {
            this.$swal({
              icon: "info",
              title: "Oops...",
              text: res.error
            });
          });
        });
    }
  },

  computed: {
    resultQuery() {
      if (this.searchQuery) {
        return this.options.filter((item) => {
          return this.searchQuery
            .toLowerCase()
            .split(" ")
            .every((v) => item.fullName.toLowerCase().includes(v));
        });
      } else {
        return this.resources;
      }
    },
    calCulateTotal() {
      var sum = 0;

      this.medications.filter((med) => {
        sum += med.total;
      });

      this.$store.dispatch("pharmacy/SET_SUM", sum);
      return sum;
    },
    getCustomerPhone() {
      let phonenumber =
        this.$store.state.pharmacy.mainphonenumber || "Customer phone number";
      return phonenumber;
    }
  },
  mounted() {
    this.GetInvoiceRequiredData();
    this.getCustomerPhone;
  }
};
</script>

<style scoped>
.mtbtn{
  margin-top: 30px;
}
.iconify {
  color: red !important;
}

.btn-info {
  background: #f15825 !important;
  border: none;
}

.btn-success {
  background: #0c4369 !important;
  border: none;
}
</style>
